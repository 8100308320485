import { render, staticRenderFns } from "./modificationsList.vue?vue&type=template&id=252e1d16&scoped=true&"
import script from "./modificationsList.vue?vue&type=script&lang=js&"
export * from "./modificationsList.vue?vue&type=script&lang=js&"
import style0 from "./modificationsList.vue?vue&type=style&index=0&id=252e1d16&scoped=true&lang=css&"
import style1 from "./modificationsList.vue?vue&type=style&index=1&id=252e1d16&lang=scss&scoped=true&"
import style2 from "./modificationsList.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252e1d16",
  null
  
)

export default component.exports